<template>
  <header class="topHeader">
    <nav class="navbar navbar-expand-xl navbar-light " :class="navClass">
      <div class="wrapper">
        <a class="navbar-brand one" :href="path('lighty_share_lighty_share_homepage')">
          <img src="../assets/logo-dark.png" alt="LightyShare" />
        </a>
        <div v-if="(noSearch && user) || isSearch" class="two">
          <SearchForm :active-suggestion="true"></SearchForm>
        </div>
        <div v-else-if="!user">
          <PublicMenu></PublicMenu>
        </div>
        <button v-if="hasBurgerMenu" class="navbar-toggler" type="button" @click="toogleOwnerMenu">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div v-if="hasBurgerMenu" class="navbar-nav mr-auto loueurNavSandwich" :class="ownerMenuClass">
          <div class="top-nav-menu">
            <ul>
              <li>
                <a :href="path('lighty_share_user_tableau_de_bord')" :class="isActive('/owner/dashboard')">
                  {{$t('navbar-auth.submenu.0')}}
                </a>
              </li>
              <li>
                <a :href="path('lighty_share_user_mes_reservations_proprietaire')" :class="isActive('/owner/rental')"> {{$t('navbar-auth.submenu.1')}}
                  <span v-if="countNewRentals !== 0" class="badge-notification" style="position: relative;top: -3px;">
                      {{countNewRentals}}
                  </span>
                </a>
              </li>
              <li>
                <a :href="path('lighty_share_user_mes_annonces')" :class="isActive('/owner/listing')">
                  {{$t('navbar-auth.submenu.2')}}
                </a>
              </li>
              <li>
                <a :href="path('lighty_share_user_mes_revenus')" :class="isActive('/owner/revenus')">
                  {{$t('navbar-auth.submenu.3')}}
                </a>
              </li>
              <li>
                <a :href="path('lighty_share_lighty_share_owner_settings')" :class="isActive('/owner-settings')">
                  {{$t('navbar-auth.submenu.4')}}
                </a>
              </li>
            </ul>
          </div>
        </div>

        <ul v-if="!user" class="navbar-nav auth-menu desktop-only">
          <li class="nav-item">
            <a class="nav-link" href="/connexion">{{$t('navbar.itemLogin.1')}}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link user-menu" href="/inscription">{{$t('navbar.itemLogin.2')}}</a>
          </li>
        </ul>
        <AuthNavMenu v-else :routerLinks="routerLinks" :notifications="notifications"></AuthNavMenu>
      </div>
    </nav>
  </header>
  <div id="menu-container">
    <div id="menu-wrapper">
      <div id="hamburger-menu"><span></span><span></span><span></span></div>
      <!-- hamburger-menu -->
    </div>
  </div>
  <div v-if="user && bottomMenu" class="cart-page-bottom mobile-only">
    <ul class="horizontally-menu mobile-bottom-bar">
      <li>
        <a href="/catalogue-search">
          <span class="icon-kit">&#xe928</span>
          <span>Rechercher</span>
        </a>
      </li>
      <li>
        <a href="/locataire/reservations/en-attentes">
          <span class="icon-kit">&#xea16</span>
          <span>Locations</span>
        </a>
      </li>
      <li>
        <a :href="path('lighty_share_user_messagerie_default')">
          <span class="icon-kit">&#xe999</span>
          <span>Messagerie</span>
        </a>
      </li>
      <li>
        <a href="/" @click.prevent="toogleMenu">
          <span class="icon-kit">&#xe998</span>
          <span>Menu</span>
        </a>
      </li>
    </ul>
    <div class="collapse navbar-collapse" id="slide-menu-collapse" :style="displayStyle">
      <div class="drawer-header">
        <h3 class="drawer-header-logo">Menu</h3>
        <span class="close-icon" @click="toogleMenu"><span class="icon-kit">&#xe933</span></span>
      </div>
      <div class="profile-name">
        <img v-if="profileThumb" :src="'images-upload/' + $symfony.auth.id + '/profil/' + profileThumb" alt="" class="user-image" />
        <div v-else class="avatar avatar-sm-32 avatar-color-1">
          <div class="initials">{{ initials }}</div>
        </div>
        <span>{{ $symfony.auth.prenom }}</span>
      </div>
      <h4 class="voir-profile-link">
        <a :href="path('my_user_show', {id : $symfony.auth.id})"><span>Voir mon profil</span> <i class="icon-chevron-right"></i> </a>
      </h4>
      <ul class="left-menu">
        <li>
          <a :href="path('lightyshare_share_lighty_share_my_favorites')">
            <i class="icon-heart"></i>
            Favoris
          </a>
        </li>
        <li>
          <a :href="path('lighty_share_user_mes_paniers')">
            <i class="icon-shopping-cart"></i>
            Panier
          </a>
        </li>
        <li>
          <a href="/locataire/reservations/en-attentes">
            <i class="icon-calendar"></i>
            Locations
          </a>
        </li>
        <li>
          <a :href="path('lighty_share_user_messagerie_default')">
            <i class="icon-message-circle"></i>
            Messagerie
          </a>
        </li>
        <li>
          <a href="/catalogue-search">
            <i class="icon-compass"></i>
            Rechercher
          </a>
        </li>
        <li v-if="$symfony.auth.boutiqueActive">
          <a :href="path('lightyshare_shop_dashboard')">
            <i class="icon-shopping-bag"></i>
            Espace loueur
          </a>
        </li>
        <li>
          <a :href="path('lighty_share_lighty_share_account_settings')">
            <i class="icon-settings"></i>
            Paramètres
          </a>
        </li>
        <li>
          <a href="https://support.lightyshare.com/">
            <i class="icon-help-circle"></i>
            Support
          </a>
        </li>
        <li class="sign-out">
          <a :href="path('fos_user_security_logout')">
            <i class="icon-log-out"></i>
            Se déconnecter
          </a>
        </li>
      </ul>
    </div>
    <!-- /.navbar-collapse -->
  </div>
  <div v-else-if="bottomMenu" class="cart-page-bottom mobile-only">
    <ul class="horizontally-menu mobile-bottom-bar">
      <li>
        <a href="/catalogue-search">
          <span class="icon-kit">&#xe928</span>
          <span>Rechercher</span>
        </a>
      </li>
      <li>
        <a href="/" @click.prevent="toogleMenu">
          <span class="icon-kit">&#xe998</span>
          <span>Menu</span>
        </a>
      </li>
    </ul>
    <div class="navbar-collapse" id="slide-menu-collapse" :style="displayStyle">
      <div class="drawer-header">
        <h3 class="drawer-header-logo">Menu</h3>
        <span class="close-icon" @click="toogleMenu"><span class="icon-kit">&#xe933</span></span>
      </div>
      <ul class="left-menu">
        <li class="menu-primary-action">
          <a class="mobile-red-button btn-signup" :href="path('lighty_share_lighty_share_inscription')">
            <i class="icon-user-plus"></i>
            S'inscrire
          </a>
        </li>
        <li class="menu-secondary-action">
          <a :href="path('lighty_share_lighty_share_connexion')">
            <i class="icon-log-in"></i>
            Se connecter
          </a>
        </li>
        <li>
          <a href="/catalogue-search">
            <i class="icon-compass"></i>
            Rechercher
          </a>
        </li>
        <li>
          <a :href="path('lighty_share_service_louez')">
            <i class="icon-camera"></i>
            Louez du materiel
          </a>
        </li>
        <li>
          <a :href="path('lighty_share_service_rentabilisez')">
            <i class="icon-trending-up"></i>
            Rentabilisez votre materiel
          </a>
        </li>
        <li>
          <a href="//blog.lightyshare.com">
            <i class="icon-pen-tool"></i>
            Blog
          </a>
        </li>
        <li>
          <a href="//support.lightyshare.com/">
            <i class="icon-help-circle"></i>
            Aide
          </a>
        </li>
        <li class="mobile-only">
          <a @click.prevent="openBeacon" href="#">
            <i class="icon-message-circle"></i>
            Nous contacter
          </a>
        </li>
      </ul>
    </div>
    <!-- /.navbar-collapse -->
  </div>
</template>

<script>
import AuthNavMenu from "./AuthNavMenu.vue";
import PublicMenu from "./PublicMenu.vue"
import SearchForm from "./SearchForm.vue";
import {loadNewNotifications} from "../services/notifications";
import notifications from "../mixins/notifications";

const routes = {
  logout: '',
  contacter: '',
  support: '',
  panier: '',
  boutique: '',
  favoris: '',

}

export default {
  name: "topBar",
  mixins: [notifications],
  components: {
    PublicMenu,
    AuthNavMenu,
    SearchForm
  },
  props: {
    oldLinks: {
      type: Boolean,
      default: false
    },
    routerLinks: {
      type: Array,
      default () {
        return ['dashboard', 'carts', 'asks']
      }
    },
    bottomMenu: {
      default: true
    }
  },
  data () {
    return {
      notifications: null,
      routes,
      activeMenu: false,
      isOwnerMenuActive: false,
      menuOpen: false,
      userInfo: {
        image: '',
        firstName: '',
        id: '',
        isTenant: false
      },
      userInitials: ''
    }
  },
  async mounted() {
    if (this.user) {
      const notification = await loadNewNotifications()
      if (notification.data) {
        this.notifications = notification.data
      }
    }
  },
  computed: {
    ownerMenuClass () {

      // return thi
    },
    isSearch () {
      return this.route.path.includes('catalogue-search')
    },
    noSearch () {
      const burgerMenuUrls = [
        '/owner-settings',
        '/owner/dashboard',
        '/owner/listing',
        '/owner/rental',
        '/owner/revenus',
        '/owner/listing-update',
        '/owner/listing-creation'
      ];
      const navClass = burgerMenuUrls.reduce((hasBurger, url) => {
        return hasBurger || this.route.path.includes(url)
      }, false)
      return !navClass
    },
    useVueRouter () {
      return !!this.$route
    },
    route () {
      if (this.$route) {
        return this.$route
      } else {
        return { path: window.location.href }
      }
    },
    navClass () {
      const burgerMenuUrls = [
        '/owner-settings',
        '/owner/dashboard',
        '/owner/listing',
        '/owner/rental',
        '/owner/revenus',
        '/owner/listing-update',
        '/owner/listing-creation'
      ];
      const navClass = burgerMenuUrls.reduce((hasBurger, url) => {
          return hasBurger || this.route.path.includes(url)
      }, false)
      return navClass ? '' : 'header-new'
    },
    hasBurgerMenu () {
      const burgerMenuUrls = [
          '/owner-settings',
          '/owner/dashboard',
          '/owner/listing',
          '/owner/rental',
          '/owner/revenus'
      ];
      return burgerMenuUrls.reduce((hasBurger, url) => {
        return hasBurger || this.route.path.includes(url)
      }, false)
    },
    isAdCreation () {
      return this.route.path.includes('/owner/listing-creation') || this.route.path.includes('/owner/listing-update')
    },
    symfony () {
      return this.$symfony
    },
    user () {
      if (this.$symfony.auth && this.$symfony.auth !== 'anon.') {
        return this.$symfony.auth
      } else {
        return false
      }
    },
    granted () {
      return this.$symfony.isGranted
    },
    initials () {
      if (this.user) {
        return this.user.prenom[0] + '' + this.user.nom[0]
      } else {
        return ''
      }
    },
    profileThumb () {
      if (this.user && this.user.ImageProfil ) {
        return this.user.ImageProfil.imageName
      } else {
        return false
      }
    },
    displayStyle () {
      return this.activeMenu ? {display: 'block'} : {display: 'none'}
    }
  },
  methods: {
    openBeacon () {
      Beacon('navigate', '/ask/')
      Beacon('open')
    },
    isActive (url) {
      return this.route.path.includes(url) ? 'active' : ''
    },
    toogleMenu () {
      this.activeMenu = !this.activeMenu
      if (this.activeMenu) {
        document.body.style.overflow = 'hidden';
        document.body.classList.add('body-slide-menu-open');
        $('#slide-menu-collapse').addClass('show');
      } else {
        document.body.style.overflow = '';
        document.body.classList.remove('body-slide-menu-open');
        $('#slide-menu-collapse').removeClass('show');
      }
    },
    toogleOwnerMenu () {
      this.isOwnerMenuActive = !this.isOwnerMenuActive
    },
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
      if (this.menuOpen) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = '';
      }
    }
  }
}
</script>

<style>
/* Styles spécifiques pour le menu dans Vue */
.collapse.navbar-collapse#slide-menu-collapse, 
.navbar-collapse#slide-menu-collapse {
  display: none;
  z-index: 20000 !important;
}

.collapse.navbar-collapse#slide-menu-collapse.show,
.navbar-collapse#slide-menu-collapse.show {
  display: block !important;
}

/* Styles pour masquer la zone de saisie de message quand le menu est ouvert */
body.body-slide-menu-open .chat-input-container {
  display: none !important;
  /* Alternative avec z-index si la propriété display:none pose problème */
  /*
  z-index: 19999 !important;
  position: relative !important;
  */
}

/* Styles pour l'en-tête du menu */
.navbar-collapse#slide-menu-collapse .drawer-header {
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding: 15px !important;
  margin-bottom: 20px !important;
  border-bottom: 1px solid #eee !important;
}

.navbar-collapse#slide-menu-collapse .drawer-header .drawer-header-logo {
  font-size: 20px !important;
  font-weight: 600 !important;
  margin: 0 !important;
  display: block !important;
}

.navbar-collapse#slide-menu-collapse .drawer-header .close-icon {
  cursor: pointer !important;
  font-size: 18px !important;
}

/* Styles pour les boutons d'action du menu */
.navbar-collapse#slide-menu-collapse .left-menu .menu-primary-action {
  margin-bottom: 15px !important;
}

.navbar-collapse#slide-menu-collapse .left-menu .menu-primary-action a.mobile-red-button {
  background-color: #fc2249 !important;
  color: white !important;
  border-radius: 4px !important;
  padding: 12px 15px !important;
  font-weight: 600 !important;
  transition: background-color 0.3s ease !important;
  display: flex !important;
  align-items: center !important;
}

.navbar-collapse#slide-menu-collapse .left-menu .menu-primary-action a.mobile-red-button i {
  margin-right: 10px !important;
  width: 20px !important;
  text-align: center !important;
}

.navbar-collapse#slide-menu-collapse .left-menu .menu-primary-action a.mobile-red-button:hover {
  background-color: #e41e43 !important;
}

.navbar-collapse#slide-menu-collapse .left-menu .menu-secondary-action {
  margin-bottom: 25px !important;
}

.navbar-collapse#slide-menu-collapse .left-menu li a {
  display: flex !important;
  align-items: center !important;
  padding: 12px 15px !important;
  border-radius: 4px !important;
}

.navbar-collapse#slide-menu-collapse .left-menu li a i {
  margin-right: 10px !important;
  width: 20px !important;
  text-align: center !important;
}
</style>



