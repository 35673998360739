<template>
  <form method="GET" autocomplete="off" @submit.prevent="" class="search-terms-form" v-click-outside="looseFocus">

    <div class="header-search-form d-flex" >
              <span class="nav-item dropdown desktop-only" ref="label">
                <a class="nav-link">
                  {{$t('navbar-auth.header-search.title')}}
                </a>
                <!--                {#-->
                <!--                <div class="dropdown-menu" aria-labelledby="navbarDropdown">-->
                <!--                  <a class="dropdown-item" href="#">Achat</a>-->
                <!--                </div>-->
                <!--                #}-->
              </span>
      <input class="form-control" id="type" name="type" type="hidden" value="rent">
      <input ref="searchField" type="text" class="input-search" name="q" v-model="searchAsk" :placeholder="$t('navbar-auth.header-search.input-search')" @focusin="onFocus" @focusout="onFocusOut" @click="onSearchFocus" @keyup.enter="onSearchEnter" @keyup.esc="onEscape">
      <button class="search-button" ref="searchButton">
        <i class="fas fa-search" @click.prevent="onSearchEnter"></i>
      </button>
    </div>
    <div v-show="showSuggestion" class="search-suggestion" :style="suggestionBoxStyle" >
      <div class="suggestion-group">
        <span class="search-group-autocomplete">Rechercher</span>
        <ul class="suggestion-list">
          <li>
            <a href="" @click.prevent="onSearchEnter" class="suggestion-item">{{searchAsk}}</a>
          </li>
        </ul>
      </div>
      <div v-for="slg in groups" class="suggestion-group">
        <span class="search-group-autocomplete">{{slg.name}}</span>
        <ul class="suggestion-list">
          <li v-for="s in slg.suggestions" @click.prevent="onSuggestionClick(s)" >
            <a href="" class="suggestion-item" v-html="s.nom"></a>
          </li>
        </ul>
      </div>
    </div>
  </form>
</template>

<script>
import {debounce, throttle} from "../utils/TimeExecution.js";
import {autocomplete, searchAction} from "../services/search.js";

const debounceFunc = debounce(1000, false, (callback, ...params) => {
  callback(...params)
});
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default {
  name: "SearchForm",
  components: {
  },
  props: {
    activeSuggestion: {
      default: true,
      type: Boolean
    },
    suggestionGroups: {
      default () {
        return true
      },
      type: [Boolean, Array]
    }
  },
  data () {
    return {
      isResultLoading: false,
      selectedMarques: null,
      selectedModeles: null,
      marqueSearch: null,
      selectedSuggestion: null,
      focusedSuggestion: null,
      suggestionResponse: null,
      currentSelectedSuggestion: {},
      // suggestionBoxStyle: {},
      searchDebounce: debounce(this.runSearchQuery),
      suggestionsLoaded: [],
      displaySuggestions: true,
      suggestionFocus: false,
      termMode: false,
      searchAsk: ''
    }
  },
  computed: {
    symfony () {
      return this.$symfony
    },
    useVueRouter () {
      return !!this.$route
    },
    route () {
      if (this.$route) {
        return this.$route
      } else {
        let params = window.location.href.split('?').length > 1 ? window.location.href.split('?')[1] : null
        const query = {};
        if (params) {
          params = params.split('&')
          params.forEach((v) => {
            const entry = v.split('=')
            if (entry && entry.length > 1) {
              query[entry[0]] = entry[1]
            }
          })
        }
        return {
          path: window.location.href,
          query
        }
      }
    },
    searchUrl () {
      return window.location.origin + '/catalogue-search'
    },
    selectedMarque: {
      set (val) {
        this.marqueSearch = val
      },
      get () {
        if (this.selectedSuggestion) {
          return this.selectedSuggestion
        }
      }
    },
    showSuggestion ()
    {
      return this.suggestionList.length && this.suggestionFocus
    },
    suggestionBoxStyle () {
      if (this.showSuggestion && this.$refs && this.$refs.label) {
        const leftPadding = this.$refs.label.offsetWidth === 0 ? 0 : 20
        const leftLabel = this.$refs.label ? this.$refs.label.offsetWidth : 0
        const boxPadding = 20
        return {
          transform: `translateY(-10px)`,
          left: leftLabel + leftPadding + 'px',
          width: (this.$refs.searchField.offsetWidth + this.$refs.searchButton.offsetWidth + boxPadding) + 'px'
        }
      } else {
        return {
          display: 'none'
        }
      }
    },
    displaySuggestionList () {
      return this.displaySuggestions
    },
    suggestionList () {
      return this.suggestionResponse === null ? [] : this.suggestionResponse.hits.hits;
    },
    suggestionListGroups () {
      return this.suggestionList.reduce((group, suggestion) => {
        if (!group.includes(suggestion._type)) {
          group.push(suggestion._type)
        }
        return group
      }, [])
    },
    groups () {
      return this.suggestionListGroups.map((v) => {
        return {
          name: capitalizeFirstLetter(v),
          suggestions: this.suggestionList.filter((suggestion) => {
            return suggestion['_type'] === v && suggestion['_source'].nom
          }).map((suggestion) => {
            let nom = suggestion['highlight'] && suggestion['highlight'].nom ? suggestion['highlight'].nom.join('') : suggestion['_source'].nom
            if (suggestion['_type'] === 'modele') {
              nom = suggestion['_source'].marque.nom + ' - ' + nom
            }
            const add = {
              type: suggestion['_type'],
              term: suggestion['_source'].nom,
              nom
            }
            return {...suggestion['_source'], ...add}
          })
        }
      })
    }
  },
  watch: {
    selectedSuggestion: {
      deep: true,
      immediate: false,
      handler (suggestion) {
        if (suggestion) {
          this.pushType(suggestion)
        }
      }
    },
    $route: {
      immediate: true,
      deep: true,
      handler (route, oldRoute) {
        // console.log('changed route')
        // console.log(route)
        // if (route.query.q !== this.searchAsk) {
        //   this.searchAsk = route.query.q
        // }
      }
    },
    searchAsk: {
      immediate: true,
      handler (val) {
        this.loadSearchAsk(val)
        if (!this.activeSuggestion) {
          this.runSearchQuery(val)
        } else {
        }
        this.runAutocomplete({q: val, types: 'categorie,modele,marque'})
      }
    }
  },
  mounted () {
    this.computeSuggestionStyle();
    window.onresize = this.computeSuggestionStyle
    if (this.$symfony && this.$symfony.marques) {
      this.selectedMarques = this.$symfony.marques
    }
  },
  methods: {
    onFocusOut () {
      // loose focus methods after half a second delay
      setTimeout(() => {
        this.looseFocus()
      }, 500)
    },
    onEscape () {
      this.suggestionFocus = false
    },
    loadSearchAsk (val) {
      if (val === '' && this.route.query['q']) {
        this.searchAsk = val
      }
      if (this.route.query['q'] || this.route.query['q'] === '') {
        this.pushType({id: val, type: 'searchTerm'})
      }
      // if (this.route.query['ma'])
    },
    pushOrUpdateSearchQuery (name, value)
    {

    },
    onSearchEnter () {
      this.pushType({
        id: this.searchAsk,
        type: 'searchTerm'
      })
      this.suggestionFocus = false
    },
    onFocus () {
      this.suggestionFocus = true
    },
    looseFocus() {
      this.suggestionFocus = false
    },
    runSearchTerm () {
      this.$router.push({query: {q: this.searchAsk}})
    },
    async test () {
      await this.$router.push({query: {sc: '1'}})
    },
    handleError () {
      console.error('Error')
    },
    autocompleteThrottle: throttle(function (data) {
      autocomplete(data)
          .then(this.saveSuggestion)
          .catch(this.handleError)
          .finally(() => {
            this.isResultLoading = false
          })
    }, 1000, true, true),
    selectSuggestion(slq) {
      this.searchAsk = slq.term
      this.selectedSuggestion = slq
      this.pushSelectedSuggestionQuery(slq)
      this.suggestionFocus = false
    },
    pushSelectedSuggestionQuery (slg) {
      this.pushType(slg)
      // this.searchAsk = ''
      // this.displaySuggestions = false
    },
    pushType (slg) {
      // this.$router.push({query: {t: 'proud'}})
      const Types = {
        modele: 'mo',
        searchTerm: 'q',
        marque: 'ma',
        categorie: 'sc'
      }

      let filters = {
        ...this.route.query
      }
      if (slg.type === 'marque') {
        delete filters['mo']
        delete filters['q']
      }
      if (slg.type === 'searchTerm') {
        delete filters['mo']
      }
      if (slg.type === 'modele') {
        delete filters['ma']
        delete filters['q']
      }
      if (slg.type === 'categorie') {
        delete filters['ma']
        delete filters['mo']
      }
      if (Types[slg.type]) {
        filters[Types[slg.type]] = slg.id + ''
      }
      // console.log('push router query')
      // console.log(filters)
      // if (this.$route.path.includes('catalogue-search')) {
      //   this.$router.push({query: filters})
      // } else {
      //   const params = Object.keys(filters).map((key) => { return key + '=' + filters[key]}).join('&')
      //   window.open('/catalogue-search?' + params, '_self')
      if (slg.type === 'searchTerm' && slg.id === '') {
        delete filters['q']
      }
      if (!!this.$router) {
        this.$router.push({name: 'main', query: filters})
      } else {
        if (Object.keys(filters).length > 0) {
          let filtersUrl = '?' + Object.entries(filters).map((v) => {
            return v[0] + '=' + v[1]
          }).join('&')
          window.location.href = this.searchUrl + filtersUrl;
          // window.location.reload()
        }
      }
      // console.log('push done')
      // console.log(this.$route.query)
    },
    onSuggestionClick (slg) {
      this.selectSuggestion(slg)
    },
    runAutocomplete (data) {
      this.autocompleteThrottle(data)
      // autocomplete(data)
      //   .then(this.saveSuggestion)
    },
    saveSuggestion (response) {
      this.suggestionResponse = response.data
      if (response.data['modele']) {
        this.selectedMarques = response.data['modele']['marque']
        this.selectedModeles = response.data['modele']
      } else {
        this.selectedMarques = null
        this.selectedModeles = null
      }
      if (response.data['marque']) {
        this.selectedMarques =  response.data['marque']
      } else {
        this.selectedMarques =  null
      }
    },
    onSearchFocus () {
      // console.log('search focus')
      this.displaySuggestions = true
      this.computeSuggestionStyle()
    },
    onSearchFocusOut () {
      let that = this
      setTimeout(function () {
        // that.displaySuggestions = false
      }, 500);
      // this.displaySuggestions = false
    },
    computeSuggestionStyle () {

    },
    setSearchQuery (route) {
      this.searchAsk = route.query.q
    },
    runSearchQuery (terms) {
      let filters = {
        ...this.$route.query,
        q: terms
      }
      if (terms === '') {
        delete filters.q
      }
      if (!!this.$router) {
        console.log('run search query')
        this.$router.push({ path: '', query: filters })
      } else {
        const filtersUrl = filters.reduce((query, filter, type) => {
          return `${query}&${type}=${filter}`
        }, '?')
        window.location = this.searchUrl + filtersUrl;
        window.location.reload()
      }

    }
  }
}
</script>

<style lang="scss">
  .header-search-form {
    z-index: 10;
  }
  .input-search {
    z-index: 12;
  }
  .search-group-autocomplete {
    margin: 10px;
    color: #9b9b9b;
    font-weight: bold;
  }
  .search-suggestion {
    display: block;
    z-index: 11;
    background: #ffffff;
    color: #333333;
    position: absolute;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 10px 17px 0px rgb(0 0 0 / 10%);
    border: 1px solid #F4F4F4;
    border-top: none;
  }

  .suggestion-group:first-child {
    margin-top: 30px;
  }
  .no-padding.suggestion-group {
    margin-top: 0;
  }
  .search-terms-form {
    position: relative;
  }
  .hlt1 {
    font-weight: bold;
    //color: #080025;
    font-style: normal;
  }
</style>
